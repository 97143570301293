<template>
  <div>
    <heads></heads>
    <div class="main">
      <div class="title">{{ type == 1 ? '密码登录' : '验证码登录' }}</div>
      <!-- 账号密码登录 -->
      <div v-if="numer_login" class="numer_login">
        <div class="phone">
          <div class="showpassword">
            <input v-model="num" type="text" placeholder="请输入用户名/手机号" @input="numInput">
            <img v-show="closeNumShow" class="eyes" src="https://newoss.zhulong.com/forum/202303/15/11/1678851520953645.png" alt="" @click="closeNumClick">
          </div>
        </div>
        <div class="password">
          <div class="showpassword">
            <input v-show="is_pass" v-model="password" type="password" placeholder="请输入密码">
            <input v-show="!is_pass" v-model="password" type="text" placeholder="请输入密码">
            <img v-show="is_pass" class="eyes" src="https://newoss.zhulong.com/forum/202303/15/16/1678868449553905.png" alt="" @click="lookPassword">
            <img v-show="!is_pass" class="eyes" src="https://newoss.zhulong.com/forum/202303/15/16/1678868429351617.png" alt="" @click="lookPassword">
          </div>
          <!-- <div v-show="is_code">
            <input v-model="password" type="text" placeholder="请输入密码">
            <van-icon name="eye-o" />
          </div> -->
        </div>
      </div>
      <!-- 验证码登录 -->
      <div v-if="code_login" class="code_login">
        <div class="phone">
          <div class="diqu">
            <input v-model="guoji" type="text">
          </div>
          <div class="sj">
            <input v-model="phone" style="background: #fff !important" class="phone_inp" type="text" placeholder="请输入手机号" @input="ucodeInput">
            <img v-show="closePhoneShow" class="eyes" src="https://newoss.zhulong.com/forum/202303/15/11/1678851520953645.png" alt="" @click="closePhoneClick">
          </div>
        </div>
        <div class="code_box">
          <div class="code_m">
            <input v-model="ucode" type="text" maxlength="4" placeholder="请输入验证码">
          </div>
          <div v-show="iscode" class="get_code" @click="getcode()">
            {{ codeBtnText }}
          </div>
          <div v-show="!iscode" class="get_code_grey">
            {{ codeBtnText }}
          </div>
        </div>
      </div>

      <div v-show="isShowBtn && num && password" :disabled="isdisabled" class="login_btn" @click="sign_in(1)">
        登录
      </div>
      <div v-show="isShowBtn && (!num || !password)" :disabled="isdisabled" class="login_btn_yzm login_btn_yzm_tm" @click="sign_in(1)">
        登录
      </div>
      <div v-show="!isShowBtn && phone && ucode" :disabled="isdisabled" class="login_btn_yzm" @click="sign_in(2)">
        登录/注册
      </div>
      <div v-show="!isShowBtn && (!phone || !ucode)" :disabled="isdisabled" class="login_btn_yzm login_btn_yzm_tm" @click="sign_in(2)">
        登录/注册
      </div>
      <div v-show="isShowBtn" class="forget_pass"><span @click="forgetPassword">忘记密码</span></div>
    </div>
    <!-- 遮罩等待 -->
    <loadingView v-show="aloading" />
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import { Toast } from 'vant'
import { signIn, loginByUserName, loginByPhone, sendMobileCode, InsetBind } from '@/api/login.js'
import { getagencyInfo } from '@/api/hometop.js'
import { setWeChatBind } from '@/api/wxbind.js'
import WechatState from '@/utils/isWechatState.js'
import loadingView from '@/compontens/loadingView.vue'

export default {
  components: {
    heads,
    loadingView
  },
  data() {
    return {
      aloading: false,
      type: '',
      phone: '',
      guoji: '+86',
      codeBtnText: '获取验证码',
      numer_login: null,
      code_login: null,
      isShowBtn: null,
      num: '',
      ucode: '',
      password: '',
      is_pass: true,
      is_code: '',
      isdisabled: false,
      iscode: false,
      popup_id: 0,
      redirect: '',
      closeNumShow: false,
      closePhoneShow: false
    }
  },
  created() {
    this.type = this.$route.query.type
    this.getagencyInfoFun()
    // 加载滑块js
    this.loadTCaptchaJS()
  },
  mounted() {
    if (this.type == 1) {
      this.numer_login = true
      this.code_login = false
      this.isShowBtn = true
    } else {
      this.code_login = true
      this.numer_login = false
      this.isShowBtn = false
    }
    this.getParams()
  },
  methods: {
    // 加载滑块验证码
    loadTCaptchaJS() {
      if (!WechatState.isWechatMiniprogram && !document.getElementById('loadTCaptchaJS')) {
        // 创建script标签，引入外部文件
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = './TCaptcha.js'
        script.id = 'loadTCaptchaJS'
        document.getElementsByTagName('head')[0].appendChild(script)
      }
    },
    getParams() {
      if (this.$route.query.redirect) {
        this.redirect = decodeURIComponent(this.$route.query.redirect)
      } else {
        this.redirect = window.location.origin
      }
      console.log(this.redirect, 'this.redirect')
    },
    getagencyInfoFun() {
      getagencyInfo().then(res => {
        this.thumb = res.result.thumb
        this.short_name = res.result.short_name
        console.log(res)
      })
    },
    getcode() {
      if (this.timer) return
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (this.guoji == '86' || this.guoji == '+86') {
        if (!reg.test(this.phone)) {
          Toast('手机号格式有误')
          return false
        }
      } else {
        if (!this.phone || this.phone.length <= 0) {
          Toast('请输入手机号')
          return false
        }
      }

      if (WechatState.isWechatMiniprogram) {
        // 直接发验证码
        this.sendYzmNoCheck()
      } else {
        // 滑块验证
        this.useTencentCheck()
      }
    },
    // 不验证滑块的 直接发验证码
    sendYzmNoCheck() {
      const phone = this.phone
      var params = {
        type: 1,
        mobile: phone,
        code_type: 1,
        client_id: 500
      }
      sendMobileCode(params).then((res) => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    // 验证
    useTencentCheck() {
      console.log('滑块验证')
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        console.log(res)
        if (res.ret == 0) {
          console.log(111)
          // 成功，传递数据给后台进行验证
          that.randstr = res.randstr
          that.ticket = res.ticket
          that.sendYzm()
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    down_() {
      this.timer = setInterval(this.timerAction, 1000)
    },
    // 验证码手机号监听
    ucodeInput() {
      if (this.phone) {
        this.iscode = true
        this.closePhoneShow = true
      } else {
        this.iscode = false
        this.closePhoneShow = false
      }
    },
    // 手机号码监听
    numInput() {
      if (this.num) {
        this.closeNumShow = true
      } else {
        this.closeNumShow = false
      }
    },
    // 清空账号密码手机号
    closeNumClick() {
      this.num = ''
      this.closeNumShow = false
    },
    // 清空验证码登录手机号
    closePhoneClick() {
      this.phone = ''
      this.iscode = false
      this.closePhoneShow = false
    },
    // 倒计时方法
    timerAction() {
      let time = this.codeCount
      if (time > 0) {
        const seconds = Math.floor(time % 60)
        const title = seconds + 's'
        time -= 1
        this.codeCount = time
        this.codeBtnText = title
      } else {
        this.closeTimer()
        this.codeCount = 59
        this.codeBtnText = '重新获取'
      }
    },
    // 关闭倒计时
    closeTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    sendYzm() {
      var params = {
        app_id: 1,
        mobile: this.phone,
        ticket: this.ticket,
        randstr: this.randstr,
        code_type: 1
      }
      sendMobileCode(params).then(res => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        } else {
          Toast(res.msg || '接口报错')
        }
      })
    },
    // 登录
    sign_in(type) {
      // 账号密码登录
      if (type === 1) {
        if (!this.num) {
          Toast('请输入手机号')
          return
        }
        if (!this.password) {
          Toast('请输入密码')
          return
        }
        this.aloading = true
        var params = {
          loginname: this.num,
          password: this.password,
          from_url: 'wap',
          type: 2,
          agency_id: Cookies.get('agency_id') || 2
        }
        loginByUserName(params).then(res => {
          // 检测是否是上个页面授权过来的
          if (this.$route.query.openid && this.$route.query.unionid && this.$route.query.nickname) {
            this.callForBindWechat(res)
          } else {
            this.aloading = false
            // 登录成功
            this.loginSuccessActions(res)
          }
        }).catch(() => {
          this.aloading = false
        })
      } else {
        // 手机号登录
        if (!this.phone) {
          Toast('请输入手机号')
          return
        }
        if (!this.ucode) {
          Toast('请输入验证码')
          return
        }
        this.aloading = true
        params = {
          mobile: this.phone,
          code: this.ucode,
          agency_id: Cookies.get('agency_id') || 2,
          type: 1,
          from_url: 'wap'
        }
        loginByPhone(params).then(res => {
          // 检测是否是上个页面授权过来的
          if (this.$route.query.openid && this.$route.query.unionid && this.$route.query.nickname) {
            this.callForBindWechat(res)
          } else {
            this.aloading = false
            // 登录成功
            this.loginSuccessActions(res)
          }
        }).catch(() => {
          this.aloading = false
        })
      }
    },
    // 登录接口调成功之后的处理，上面写在一起太恶心了，搞个方法处理，但是内容不变
    loginSuccessActions(res) {
      console.log(res)
      const errNo = res.errNo
      // 0、优先检测是否需要绑定手机号
      if (errNo == 160) {
        Toast('需要绑定手机号')
        this.$router.push({
          path: '/set_up/addPhone',
          query: {
            uuid: res.result.uuid,
            access_token: res.result.access_token
          }
        })
        return false
      }
      if (errNo == 0) {
        const result = res.result ? res.result : {}
        // 1、popup_id 处理
        if (this.popup_id) {
          window.location.href = `https://m.zhulong.com/interview/#/hrresume?id=${this.popup_id}`
          return false
        }

        // // 2、reg 处理
        // // const type = result.type ? result.type : ''
        // // 流程不通，暂时关闭
        // const type = ''
        // if (type === 'reg') {
        //   // 增加新用户注册
        //   this.newRegistInfo()
        //   return false
        // }

        // 3、unionID 处理
        if (this.$route.query.unionID) {
          this.setWeChatBind(res.result.uid)
        }

        // 4、关注公众号  处理
        if (this.canShowAttGzh(result)) {
          // 关注公众号
          this.showAttention()
          return
        }

        // 5、回到首页或redirect页面
        window.location.href = this.redirect
      } else {
        Toast(res.msg)
      }
    },
    // 是否出关注公众号，是否加判断是从拼团过来的 待定
    canShowAttGzh(val) {
      const is_bind_gongzh = val.is_bind_gongzh || ''
      const uid = val.uid || ''
      const isWxH5 = WechatState.isWechat && !WechatState.isWechatMiniprogram && !WechatState.wxwork
      // 微信h5、普通h5，即：非小程序、非app
      if ((isWxH5 && is_bind_gongzh != 1) || uid == 8588314) {
        return true
      }
      return false
    },
    // 关注公众号
    showAttention() {
      // att_gzh
      const redirect = this.redirect
      this.$router.push({
        path: 'att_gzh',
        query: {
          redirect: redirect
        }
      })
    },
    // 根据unionID和uid进行微信绑定
    setWeChatBind(uid) {
      const info = {
        unionid: this.$route.query.unionID,
        uid: uid
      }
      setWeChatBind(info).then(res => {
        console.log(res)
      })
    },
    // 新的绑定 : 绑定接口
    callForBindWechat(ares) {
      const openid = this.$route.query.openid
      const unionid = this.$route.query.unionid
      const nickname = decodeURIComponent(this.$route.query.nickname)
      const headimgurl = this.$route.query.headimgurl || ''
      const query = {
        openid: openid,
        unionid: unionid,
        nickname: nickname,
        headimgurl: headimgurl
      }
      InsetBind(query).then(res => {
        console.log(res, 'resres')
      }).then(() => {
        this.aloading = false
        // 登录成功
        this.loginSuccessActions(ares)
      })
    },
    // 新注册信息页面
    newRegistInfo() {
      const redirect = this.redirect
      this.$router.push({ path: '/regInfo', query: { redirect: redirect }})
    },
    // 跳转到注册页面
    register() {
      this.$router.push({ path: '/login/register' })
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push({ path: '/forgetPassword' })
    },

    // 查看密码
    lookPassword() {
      if (this.is_pass) {
        this.is_pass = false
      } else {
        this.is_pass = true
      }
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/.header{
  border: none;
}
.main{
  padding: 0 30px;
  .title{
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    color: rgba(16, 16, 16, 1);
    font-family: PingFangSC-semiBold;
    margin: 40px 0 30px;
  }
  .numer_login{
    width: 100%;
    height: 180px;
    // border-bottom: solid 1px #dcdcdc;
    .phone {
      height: 90px;
      font-size: 32px;
      line-height: 90px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      .showpassword{
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 85%;
        }
        .eyes {
          width: 30px;
          // line-height: 88px;
          margin-right: 20px;
          vertical-align: middle;
        }
      }
    }
    .password {
      height: 90px;
      font-size: 32px;
      line-height: 90px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      .showpassword{
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 85%;
        }
        .eyes {
          width: 30px;
          // line-height: 88px;
          margin-right: 20px;
          vertical-align: middle;
        }
      }
    }
  }
  .code_login{
    .phone{
      width: 100%;
      height: 90px;
      display: flex;
      margin-bottom: 2px;
      justify-content: space-between;
      .diqu{
        width: 70px;
        flex-shrink: 0;
        height: 90px;
        border-bottom: solid 1px #dcdcdc;
        input {
          width: 100%;
          font-size: 30px;
          height: 90px;
          line-height: 90px;
        }
      }
      .sj{
        width: calc(100% - 103px);;
        height: 90px;
        border-bottom: solid 1px #dcdcdc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 85%;
        }
        .eyes {
          width: 30px;
          // line-height: 88px;
          margin-right: 20px;
          vertical-align: middle;
        }
        input {
          width: 100%;
          font-size: 30px;
          height: 90px;
          line-height: 90px;
        }
      }
    }
    .code_box{
      width: 100%;
      min-height: 90px;
      display: flex;
      border-bottom: solid 1px #dcdcdc;
      justify-content: space-between;
      align-items: center;
      .code_m {
        width: 70%;
        min-height: 90px;
        input {
          width: 100%;
          line-height: 90px;
          font-size: 32px;
        }
      }
      .get_code{
        padding: 0 10px;
        height: 52px;
        border-radius: 26px;
        text-align: center;
        line-height: 54px;
        border: 1px solid #ee2e2e;
        color: #ee2e2e;
        font-size: 28px;
      }
      .get_code_grey{
        padding: 0 10px;
        height: 52px;
        border-radius: 26px;
        text-align: center;
        line-height: 54px;
        border: 1px solid rgba(238, 46, 46, 1);
        background-color: rgba(255, 255, 255, 1);
        color: rgba(238, 46, 46, 1);
        opacity: 0.5;
        font-size: 28px;
      }
    }
  }
}

.login_btn {
  width: 100%;
  height: 88px;
  background: #ee2e2e;
  border-radius: 10px;
  line-height: 90px;
  font-size: 32px;
  color: #fff;
  margin: 68px 0 35px;
  text-align: center;
  font-weight: 600;
}
.login_btn_yzm {
  width: 100%;
  height: 88px;
  background: #ee2e2e;
  border-radius: 10px;
  line-height: 90px;
  font-size: 32px;
  color: #fff;
  margin: 68px 0 35px;
  text-align: center;
  font-weight: 600;
}
.forget_pass {
  text-align: center;
  color: #ee2e2e;
  line-height: 40px;
  font-size: 28px;
}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
	color:rgba(153, 153, 153, 1);
	font-size:28px;
}

input:-moz-placeholder,textarea:-moz-placeholder{
	color:rgba(153, 153, 153, 1);
	font-size:28px;
}

input::-moz-placeholder,textarea::-moz-placeholder{
	color:rgba(153, 153, 153, 1);
	font-size:28px;
}
input:-ms-input-placeholder,textarea:-ms-input-placeholder{
	color:rgba(153, 153, 153, 1);
	font-size:28px;
}
.login_btn_yzm_tm{
  opacity: 0.5;
}
</style>
