import request from '@/utils/ucenterrequest'

// 根据unionID和uid进行微信绑定
export function setWeChatBind(params) { // 账号和密码登录
  return request({
    url: '/api/wechat/setWeChatBind',
    method: 'get',
    params
  })
}
