<template>
  <div class="loadingBox" :style="'background-color:'+bgcolor">
    <van-loading type="spinner" size="24px" />
  </div>
</template>

<script>
export default {
  props: {
    bgcolor: {
      type: String,
      default: 'transparent'
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.loadingBox {
  width: 100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(0,0,0,0.2);
  position: fixed;
  z-index: 2200;
  top: 0;
  left:0;
  .van-loading {
    color: #aaa !important;
  }
}
</style>
